<template>
  <div class="map_wrap">

    <iframe
      id="gmap_canvas"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2741.818607737979!2d14.265751051314378!3d46.59082317902795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477075ab747ae23d%3A0x2fcff010787eb37!2sStift-Viktring-Stra%C3%9Fe%208%2C%209073%20Klagenfurt%20am%20W%C3%B6rthersee!5e0!3m2!1sen!2sat!4v1658238781622!5m2!1sen!2sat"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
    ></iframe>
  </div>
  <!-- End Google Map -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>

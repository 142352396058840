<template>
    <div class="waxon_tm_service" id="service">
      <div class="container">

        <div class="waxon_tm_main_title">

          <div class="title" v-scroll-reveal="{ delay: 20, distance: '20px' }">
            <h3>Was ich biete, womit ich arbeite... (Auswahl)<span class="bg">Service</span></h3>
          </div>
        </div>

        <div class="service_inner">
          <ul>

            <hooper :settings="hooperSettings">

              <slide v-for="(service, i) in serviceContent" :key="i">

                <div class="list_inner">
                  <img class="svg" :src="service.imgSrc" alt="service image" />
                  <div class="details">
                    <h3>{{ service.title }}</h3>
                    <p>{{ service.desc }}</p>
                  </div>
                </div>

              </slide>

              <hooper-navigation slot="hooper-addons"></hooper-navigation>

            </hooper>

          </ul>
        </div>

        <p>
          ... und noch vieles andere mehr.
        </p>
        <p>
          Ich habe sicher ein passendes Werkzeug um Sie zu unterstützen und Ihre Idee zu realisieren.
        </p>

      </div>
    </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {

    return {

      serviceContent: [
        {
          imgSrc: require("../assets/img/service/c++.png"),
          title: "Modern C++ Development",
          desc: ` Modernes C++ (beginnend mit C++11) hat diese Sprache
                  revolutioniert. Ich fertige dazu qualitativ hochwerte Lösungen.`,
        },
        {
          imgSrc: require("../assets/img/service/python.png"),
          title: "Python",
          desc: ` Python ist eine Sprache die mehr und mehr an Bedeutung gewinnt.
                  Von kleinen ad-hoc Scripten bis hin zu umfangreichen GUIs, von
                  Multi-Core Server bis zu Embedded Devices ist mit Python alles
                  machbar.`,
        },
        {
          imgSrc: require("../assets/img/service/html5.png"),
          title: "HTML5",
          desc: ` HTLM5 (HTML, JavaScript und CSS) ist das 1x1, das Alphabet des Netzes.
                  Grundwerkzeug.`,
        },
        {
          imgSrc: require("../assets/img/service/typescript.png"),
          title: "TypeScript",
          desc: ` Mit TypeScript als *das* Werkzeug zur Sprache für Web Applikationen
                  erstelle ich Frontends.`,
        },
        {
          imgSrc: require("../assets/img/service/rust.png"),
          title: "Rust",
          desc: ` Ich beschäftige mich mit Rust seit einiger Zeit und brenne darauf diese
                  Technologie einzusetzen. Mit den integrierten Sicherheitskonzepten und
                  der steigenden Popularität ist meine Hoffnung, dass Rust eine neue Ära
                  stabiler Software einleutet.`,
        },
        {
          imgSrc: require("../assets/img/service/qt.png"),
          title: "Qt",
          desc: ` Qt ist *das* Framework für native GUI Applikationen, welche bei gleicher
                  Codebase auf Windows, MacOS und Linux lauffähig sind. Neben dieser Eigenschaft
                  verfügt der Kern von Qt über eine designtechnische Schönheit an der sich
                  andere messen lassen müssen! - Gut: es gibt die Tendenz zum Bloat
                  allerdings auch.`,
        },
        {
          imgSrc: require("../assets/img/service/fastapi.png"),
          title: "FastAPI",
          desc: ` FastAPI ist schlichtweg *das* ideale Framework für RESTful APIs:
                  schnell, umfangreich, dabei mit minimal scaffolling und bloat.`,
        },
        {
          imgSrc: require("../assets/img/service/vuejs.png"),
          title: "Vue.js",
          desc: ` Vue.js ist die moderne Art und Weise, wie man moderne
                  SPA (Single-Page-Applications) mit TypeScript und NodeJS baut
                  (diese Seite ist mit Vue.js gebaut).`,
        },
        {
          imgSrc: require("../assets/img/service/tailwindcss.png"),
          title: "Tailwind CSS",
          desc: ` Tailwind CSS bietet einen hervorragenden Satz an
                  Webkomponenten um damit moderne, attraktive Frontends zu gestalten.`,
        },
        {
          imgSrc: require("../assets/img/service/linux.png"),
          title: "Linux",
          desc: ` Linux ist für mich die Platform auf der ich mich vollends frei
                  und uneingeschränkt entfalten kann. Linux *ist* sehr, sehr, sehr
                  userfreundlich - wenn man denn mal Freundschaft geschlossen hat.`,
        },
        {
          imgSrc: require("../assets/img/service/android.png"),
          title: "Android",
          desc: ` Für die mobile Welt entwickle ich Apps mit dem Android Studio.`,
        },
        {
          imgSrc: require("../assets/img/service/raspberry-pi.png"),
          title: "Raspberry Pi",
          desc: ` Der Raspberry Pi hat die embedded Devices in das Wohnzimmer gebracht.
                  Ich fertige dazu ebenfalls Tools und Lösungen an.`,
        },
        {
          imgSrc: require("../assets/img/service/docker.png"),
          title: "Docker",
          desc: ` Docker ist für CI/CD im Speziellen und Webservices im Allgemeinen
                  nicht mehr wegzudenken. Mit Docker kapsle ich über einen reverse-proxy
                  wie nginx oder Caddy Services in Docker.`,
        },
        {
          imgSrc: require("../assets/img/service/git.png"),
          title: "Git",
          desc: ` Git ist an sich ja "nur" ein Software Versionierungstool...
                  aber es ist erstaunlich, wie oft man die Konzepte und Power
                  von Git in Projekten und Applikationen rein für den
                  Business Case einsetzen kann.`,
        },
      ],

      hooperSettings: {

        infiniteScroll: true,
        wheelControl: false,
        autoPlay: true,
        mouseDrag: true,
        touchDrag: true,
        transition: 800,
        playSpeed: 3000,
        breakpoints: {
          1200: {
            itemsToShow: 3,
          },
          576: {
            itemsToShow: 2,
          },
          280: {
            itemsToShow: 1,
          },
        },
      },

    };
  },
};
</script>

<style lang="scss">
.service_inner {
  .hooper-slide {
    padding-right: 44px;
  }
}
</style>
